import React, { useState, useEffect, Suspense } from "react";
import { supabase } from "../createClient";
import { Helmet } from "react-helmet";
// const BlogPost = React.lazy(() => import("../components/BlogPost"));
import { Card } from "flowbite-react";
import { useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Button } from "flowbite-react";
import { Flowbite } from "flowbite-react";
import { Link } from "react-router-dom";
const SubBlog = () => {
  // Here is where you would fetch your blog posts from your client and save them to state
  const [blogPosts, setBlogPosts] = useState([]);
  const { slug } = useParams();
  const navigate = useNavigate();
  const customTheme = {
    theme: {
      card: {
        img: {
          horizontal: {
            off: "rounded-t-lg w-full h-40 object-cover",
          },
        },
      },
    },
  };
  // const customTheme = {
  //   theme: {
  //     card: {
  //         root: {
  //           base: "flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800",
  //           children: "flex h-full flex-col justify-center gap-4 p-6",
  //           horizontal: {
  //             off: "flex-col",
  //             on: "flex-col md:max-w-xl md:flex-row"
  //           },
  //           href: "hover:bg-gray-100 dark:hover:bg-gray-700"
  //         },
  //         img: {
  //           base: "",
  //           horizontal: {
  //             off: "rounded-t-lg w-full h-40 object-cover",
  //             on: "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
  //           },
  //         },
  //       },
  //     },
  //   },

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    console.log("Fetching blog posts...");
    // fetch food with given slug
    let { data: posts, error } = await supabase
      .from("food")
      .select("*")
      .eq("slug", slug);
    console.log(posts);

    const food_id = posts[0].id;
    // fetch blog posts with given food_id from posts
    let { data: blogPosts, error2 } = await supabase
      .from("posts")
      .select("*")
      .eq("food_id", food_id);
    console.log(blogPosts);

    if (error || error2) console.log("error", error, error2);
    else setBlogPosts(blogPosts);
  };

  const [itemsToShow, setItemsToShow] = useState([0, 8]);

  const loadNext = () => {
    if (itemsToShow[1] >= blogPosts.length) return;
    setItemsToShow([itemsToShow[0] + 8, itemsToShow[1] + 8]);
  };

  const loadPrev = () => {
    if (itemsToShow[0] === 0) return;
    setItemsToShow([itemsToShow[0] - 8, itemsToShow[1] - 8]);
  };

  //   Finally understand the menu at any restaurant!
  //   No More Guesswork! Understand every dish's name and order precisely what you crave.

  //   🌍 Hundreds of cuisines covered.
  //   ⚡ Quick and user-friendly.
  //   💰 Completely free!

  return (
    <div className="flex flex-col text-black items-center p-6 w-full space-y-6 bg-white">
      <Helmet>
        <title>Blog | MenuMystic!</title>
        <meta
          name="description"
          content="MenuMystic is a free app that helps you understand the menu at any restaurant. No more guesswork! Understand every dish's name and order precisely what you crave."
        />
        {/* <meta
          name="keywords"
          content="study smarter, test taking strategies, homework help websites"
        /> */}
        <link
          rel="canonical"
          href={`https://www.menumystic.com/blog/${slug}`}
        />
      </Helmet>
      {/* <button onClick={()=>generateSitemap}>Generate Sitemap</button> */}
      <h1 className="text-5xl font-bold mt-10 mb-10 text-gray-900 bg-clip-text  ">
        Here is our
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#FF6E00] to-[#FFC700]">
          {" "}
          blog about food and cuisine around the world!
        </span>
      </h1>
      <div>
        {/* <Button onClick={addArticle}>New</Button> */}
        <div className="grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
          <Suspense fallback={<div>Loading...</div>}>
            {blogPosts.slice(itemsToShow[0], itemsToShow[1]).map((post) => (
              // w-full h-96
              <Flowbite theme={customTheme}>
                <Card imgAlt={post.alt} imgSrc={post.image_url}>
                  <h3 className="text-[24px] font-bold">{post.title}</h3>
                  <p>
                    <ReactMarkdown>
                      {post.content.length > 50
                        ? post.content.substring(0, 50) + "..."
                        : post.content}
                    </ReactMarkdown>
                  </p>
                  <Link
                    to={`/blog/posts/${post.slug}`}
                    className="text-[#FF6E00] hover:text-[#FFC700] font-bold"
                  >
                    Read More
                  </Link>
                </Card>
              </Flowbite>
            ))}
          </Suspense>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center mt-4 space-y-4 md:space-y-0 md:space-x-4">
  <Button 
    color="primary" 
    onClick={loadPrev} 
    className="w-full md:w-1/2 text-center"
  >
    Previous
  </Button>
  <Button 
    color="primary" 
    onClick={loadNext} 
    className="w-full md:w-1/2 text-center"
  >
    Next
  </Button>
</div>
      </div>
    </div>
  );
};

export default SubBlog;

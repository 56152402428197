import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { supabase } from "../createClient";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import {
  Button,
  Modal,
} from "flowbite-react";
import { HiCamera } from "react-icons/hi";
import { useNavigate } from "react-router-dom";


const BlogPostDetail = () => {
  const [post, setPost] = useState(null);
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(null);
  const { slug } = useParams();

  const handleOpenModal = () => {
    const timer = setTimeout(() => {
      setOpenModal("promote");
    }, 5000)

    console.log("timer", timer);

    return () => clearTimeout(timer);

  };

  useEffect(() => {
    handleOpenModal();
  }, []);

  useEffect(() => {
    fetchPost();
  }, [slug]);

  const fetchPost = async () => {
    try {
      const { data: blogPosts, error } = await supabase
        .from("posts")
        .select("*")
        .eq("slug", slug);

      if (error) throw error;
      setPost(blogPosts[0]);
    } catch (error) {
      console.error("Error fetching post:", error.message);
    }
  };

  if (!post) return <div className="text-center mt-10">Loading...</div>;

  return (
    <div className="bg-gray-100 text-black p-6 min-h-screen">
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.content.slice(0, 155)} />
        <link
          rel="canonical"
          href={`https://www.menumystic.com/blog/posts/${slug}`}
        />
      </Helmet>
      <div className="max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        {post.image_url && (
          <img
            src={post.image_url}
            alt={post.title}
            className="w-full h-96 object-cover rounded-lg mb-5"
          />
        )}
        <div className="mb-5">
          <h1 className="text-4xl font-bold mb-2">{post.title}</h1>
          <p className="text-gray-600 text-sm">{new Date(post.created_at).toLocaleDateString()}</p>
        </div>
        <div className="prose max-w-none">
          <ReactMarkdown>{post.content}</ReactMarkdown>
        </div>
        <div className="mt-4 md:mt-6 md:w-[210px] flex flex-col items-center">
                <Button
                  color="primary"
                  size="xl"
                  onClick={() => {
                    window.sa_event("cta_blog_post");
                    navigate("/"); // Redirect to the main screen
                  }}
                >
                  <p>Check it out!</p>
          
                </Button>
                <p className="mt-2 md:mt-3 text-gray-500 text-sm">
                  No credit card required!
                </p>
              </div>
        <div className="mt-10 text-center">
          
          <Link
            to="/blog"
            className="text-orange-700 hover:text-orange-400 transition font-semibold"
          >
            Back to Posts
          </Link>
        </div>
      </div>

      {/* Modal will promote the app and convince people to go to the main screen */}
      <Modal
  show={openModal === "promote"}
  size="md"
  popup
  onClose={() => setOpenModal(null)}
>
  <Modal.Header />
  <Modal.Body>
    <div className="flex flex-col items-center p-4 space-y-4">
      <h1 className="text-2xl font-bold text-center">
        Finally understand the menu at any restaurant!
      </h1>
      <p className="text-lg text-center">
        No More Guesswork! Learn about every food's name and order precisely what you crave.
      </p>
      <ul className="list-none text-center space-y-2">
        <li>🌍 Hundreds of cuisines covered.</li>
        <li>⚡ Food trivia & menu insights.</li>
        <li>💰 Completely free!</li>
      </ul>
      <Button
        color="primary"
        size="lg"
        onClick={() => {
          window.sa_event("cta_blog_post");
          navigate("/"); // Redirect to the main screen
        }}
        className="flex items-center space-x-2"
      >
        <p>Check it out!</p>
      </Button>
      <p className="text-gray-500 text-sm text-center">
        No credit card required!
      </p>
      <div className="w-full flex flex-col items-center">
        <div className="w-[153px] h-[89px] relative">
          <MySvg />
          {/* text in front of svg */}
          <div className="absolute top-1/2 left-1/2 z-5 flex flex-col items-center transform -translate-x-1/2 -translate-y-1/2">
            <div className="flex flex-col text-xs items-center">
              <div className="text-center text-black">
                Hundreds of happy bellies
              </div>
              <div>⭐⭐⭐⭐⭐</div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center">
        <div className="italic text-md text-center mb-2">
          Here is how it works
        </div>
        <ArrowSvg />
        <img
          className="w-1/2 max-w-xs mt-2"
          src="/iphone14.png"
          alt="iphone14 display menumystic app"
        />
      </div>
    </div>
  </Modal.Body>
</Modal>

    </div>
  );
};

export default BlogPostDetail;



const MySvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="153"
      height="82"
      viewBox="0 0 235 127"
      fill="none"
    >
      <g clip-path="url(#clip0_1017_239)">
        <path
          d="M53.2724 7.76257C50.3611 9.70741 47.1435 12.1158 44.5503 14.802C39.9492 19.568 35.386 24.9411 31.4641 30.3172C28.8147 33.9489 25.3773 39.46 23.4992 43.5138C21 48.9087 19.5196 52.6883 18.1479 58.6156C16.2156 66.9661 16.482 75.5358 18.4447 83.6149C21.3322 95.5008 30.5738 105.446 39.8087 113.519C47.0333 119.833 56.7465 123.965 65.2806 126.999L66.1624 124.682C57.7063 121.677 48.7437 117.662 41.8198 111.603C32.913 103.807 24.6862 94.3011 21.1375 82.973C18.9761 76.0772 18.4743 68.5114 19.6084 61.1848C20.6019 54.7665 23.0995 48.3341 25.5325 42.4857C27.5327 37.678 29.8649 33.9328 32.7096 29.9881C36.3164 24.9864 40.2799 20.1087 44.4932 15.6549C47.2205 12.7719 50.868 10.1692 53.7975 8.21231L53.2724 7.76257Z"
          fill="black"
        />
        <path
          d="M49.574 119.916C49.574 119.916 43.5797 118.031 38.8226 118.586C31.2704 119.468 30.5742 124.131 30.5742 124.131C30.5742 124.131 33.604 126.001 40.1024 125.29C47.7153 124.458 49.5738 119.916 49.5738 119.916L49.574 119.916Z"
          fill="black"
        />
        <path
          d="M39.3658 112.952C39.3658 112.952 34.3141 110.569 29.5246 110.604C21.2328 110.665 20.7357 115.045 20.7357 115.045C20.7357 115.045 23.737 117.199 30.3778 117.11C38.0359 117.008 39.3658 112.952 39.3658 112.952Z"
          fill="black"
        />
        <path
          d="M31.8143 105.628C31.8143 105.628 27.011 101.442 22.365 100.284C14.3217 98.2793 11.6576 102.934 11.6576 102.934C11.6576 102.934 14.6474 106.501 21.0992 108.068C28.5392 109.875 31.8143 105.628 31.8143 105.628Z"
          fill="black"
        />
        <path
          d="M23.2866 94.3478C23.2866 94.3478 19.1788 90.0414 13.5556 88.323C6.21712 86.0805 2.76778 90.4832 2.66711 90.5933C2.66711 90.5933 5.67414 94.3575 11.9478 96.0077C20.3628 98.2212 23.2866 94.3478 23.2866 94.3478Z"
          fill="black"
        />
        <path
          d="M19.225 85.3705C19.225 85.3705 15.3992 79.5764 9.69307 76.8416C4.15001 74.1834 0.100505 76.4471 0 76.5358C0 76.5358 2.84743 81.7112 8.08611 84.1912C15.2038 87.5608 19.2258 85.3699 19.2258 85.3699L19.225 85.3705Z"
          fill="black"
        />
        <path
          d="M17.9921 77.4926C15.9502 75.5822 15.7981 70.3894 12.8794 66.1756C8.80778 60.2969 2.09147 60.723 1.99097 60.7785C1.99097 60.7785 2.70998 68.1025 7.80162 72.5292C13.2128 77.2336 17.992 77.4931 17.992 77.4931L17.9921 77.4926Z"
          fill="black"
        />
        <path
          d="M17.4283 65.1475C17.4283 65.1475 17.6265 58.7817 15.9386 53.9475C13.584 47.204 7.1563 45.8801 7.04464 45.9066C7.04464 45.9066 6.00226 53.1174 9.72817 58.7305C13.688 64.6959 17.4283 65.1473 17.4283 65.1473L17.4283 65.1475Z"
          fill="black"
        />
        <path
          d="M20.1504 53.2174C20.1504 53.2174 21.4325 47.8733 20.783 42.797C19.804 35.1461 13.8177 33.5264 13.7034 33.5375C13.7034 33.5375 11.6512 40.6238 14.5706 46.6908C17.6732 53.1385 20.1504 53.2174 20.1504 53.2174Z"
          fill="black"
        />
        <path
          d="M25.4241 40.4081C25.4241 40.4081 27.5089 35.5739 27.2418 30.4637C26.871 23.3714 22.0389 20.3751 21.9243 20.371C21.9243 20.371 17.2652 24.549 19.9411 33.5423C21.5643 38.9976 25.4243 40.4082 25.4243 40.4082L25.4241 40.4081Z"
          fill="black"
        />
        <path
          d="M32.1329 29.8768C32.1329 29.8768 35.2311 25.1304 36.0882 20.0846C36.8928 15.3476 34.9604 10.1868 34.529 9.71248C34.529 9.71248 29.4879 11.916 29.6766 20.3727C29.8036 26.0606 32.1329 29.8768 32.1329 29.8768Z"
          fill="black"
        />
        <path
          d="M40.3756 19.7145C40.3756 19.7145 44.0696 15.8022 45.0318 10.4527C45.8282 6.01971 45.0254 1.20236 44.186 0C44.186 0 39.3678 2.97738 39.0177 10.5071C38.7535 16.1902 39.9935 18.8951 40.3756 19.7152L40.3756 19.7145Z"
          fill="black"
        />
        <path
          d="M46.3549 13.4821C46.3549 13.4821 53.3949 13.7351 56.8938 11.7147C60.8103 9.45316 64.6537 4.5444 64.9569 3.11139C64.9569 3.11139 58.7909 0.422327 53.0419 5.33425C48.7028 9.04144 46.6927 12.643 46.3549 13.4821Z"
          fill="black"
        />
        <path
          d="M38.5981 21.9292C38.5981 21.9292 41.9654 18.0081 47.7517 16.9951C52.2118 16.2143 55.6406 16.9352 57.842 18.2109C57.842 18.2109 54.4458 22.5385 47.9364 23.1351C42.2403 23.657 39.4348 22.2819 38.5979 21.9292L38.5981 21.9292Z"
          fill="black"
        />
        <path
          d="M31.6391 30.9698C31.6391 30.9698 34.8454 27.2088 40.6319 26.1959C45.092 25.4151 47.2337 26.216 49.435 27.4918C49.435 27.4918 46.7227 31.6194 40.2133 32.2158C34.5171 32.7378 32.4758 31.3227 31.639 30.9699L31.6391 30.9698Z"
          fill="black"
        />
        <path
          d="M24.8842 42.7637C24.8842 42.7637 27.8794 38.1282 33.2954 35.8629C38.8451 33.5415 41.6956 34.6499 44.0825 35.5361C43.3213 36.6717 40.2061 41.4054 35.1556 42.8263C29.6514 44.375 25.7689 42.9703 24.8842 42.7637Z"
          fill="black"
        />
        <path
          d="M20.8751 53.238C20.8751 53.238 23.5293 48.4013 28.7683 45.7564C34.1369 43.0461 37.0597 43.9489 39.5043 44.6631C38.8267 45.8499 36.0601 50.793 31.1247 52.5698C25.7458 54.5061 21.7724 53.3811 20.8751 53.238Z"
          fill="black"
        />
        <path
          d="M18.7694 65.6861C21.0664 62.7572 22.3151 59.6084 25.8492 56.4745C30.2032 52.6135 33.9393 53.8444 36.4748 54.0937C35.2267 56.4254 33.3525 60.1139 29.4793 62.8929C24.8402 66.2214 19.6779 65.6609 18.7694 65.6862V65.6861Z"
          fill="black"
        />
        <path
          d="M19.0545 77.1552C21.0076 73.9892 21.9344 71.5212 25.0937 68.0137C28.986 63.6925 32.6762 64.4597 35.2239 64.4253C34.2458 66.8812 32.5973 70.5551 29.061 73.7477C24.8255 77.5715 19.9543 77.029 19.0545 77.1552Z"
          fill="black"
        />
        <path
          d="M21.5663 86.3004C24.5047 81.7887 24.1339 80.7675 27.0099 77.0266C30.0986 73.0089 33.9998 73.1193 36.5365 72.8817C35.7596 75.4078 34.5989 78.8249 31.3062 82.2656C27.7949 85.935 24.1427 85.4626 21.5665 86.3004H21.5663Z"
          fill="black"
        />
        <path
          d="M26.3941 95.2619C28.2465 91.2702 27.9267 88.7776 29.6252 85.9481C32.4034 81.3204 35.1269 81.1206 37.6636 80.8832C35.6402 86.6488 36.1087 86.6754 33.8813 90.1871C31.714 93.6042 28.7691 94.0641 26.3941 95.262V95.2619Z"
          fill="black"
        />
        <path
          d="M32.2141 102.798C33.2698 98.5284 32.1979 97.2219 33.3248 94.1229C35.1678 89.0543 37.8035 88.3433 40.2485 87.6306C39.9673 91.0332 40.3918 93.1919 38.4742 96.8799C37.1201 99.4842 34.3169 101.173 32.2141 102.798Z"
          fill="black"
        />
        <path
          d="M43.945 94.4159C43.945 94.4159 46.0623 98.4815 45.359 103.193C44.3626 109.869 40.7754 110.87 40.7754 110.87C40.7754 110.87 38.7324 107.835 38.9401 101.678C39.1618 95.1015 43.9451 94.4158 43.9451 94.4158L43.945 94.4159Z"
          fill="black"
        />
        <path
          d="M51.5872 101.217C51.5872 101.217 53.7061 105.333 53.2426 110.074C52.6484 116.15 49.3027 117.671 49.3027 117.671C49.3027 117.671 47.1951 114.279 47.1053 109.159C46.9682 101.342 51.5874 101.217 51.5874 101.217L51.5872 101.217Z"
          fill="black"
        />
        <path
          d="M181.721 7.76257C184.633 9.70741 187.85 12.1158 190.443 14.802C195.044 19.568 199.608 24.9411 203.53 30.3172C206.179 33.9489 209.616 39.46 211.494 43.5138C213.994 48.9087 215.474 52.6883 216.846 58.6156C218.778 66.9661 218.512 75.5358 216.549 83.6149C213.661 95.5008 204.42 105.446 195.185 113.519C187.96 119.833 178.247 123.965 169.713 126.999L168.837 124.683C177.294 121.676 186.257 117.662 193.181 111.603C202.088 103.808 210.313 94.3024 213.864 82.9726C216.024 76.0768 216.526 68.5103 215.392 61.1845C214.399 54.7662 211.901 48.3338 209.468 42.4854C207.468 37.6777 205.136 33.9325 202.291 29.9878C198.684 24.9861 194.721 20.1084 190.507 15.6546C187.78 12.7716 184.133 10.1689 181.203 8.21199L181.721 7.76257Z"
          fill="black"
        />
        <path
          d="M185.432 119.916C185.432 119.916 191.427 118.031 196.184 118.586C203.736 119.468 204.432 124.131 204.432 124.131C204.432 124.131 201.402 126.001 194.904 125.29C187.291 124.458 185.432 119.916 185.432 119.916Z"
          fill="black"
        />
        <path
          d="M195.626 112.952C195.626 112.952 200.678 110.569 205.467 110.604C213.759 110.665 214.256 115.045 214.256 115.045C214.256 115.045 211.255 117.199 204.614 117.11C196.956 117.008 195.626 112.952 195.626 112.952Z"
          fill="black"
        />
        <path
          d="M203.192 105.628C203.192 105.628 207.995 101.442 212.641 100.284C220.685 98.2793 223.349 102.934 223.349 102.934C223.349 102.934 220.359 106.501 213.907 108.068C206.467 109.875 203.192 105.628 203.192 105.628Z"
          fill="black"
        />
        <path
          d="M211.713 94.3478C211.713 94.3478 215.821 90.0414 221.444 88.323C228.783 86.0805 232.232 90.4832 232.333 90.5933C232.333 90.5933 229.326 94.3575 223.052 96.0077C214.637 98.2212 211.714 94.3478 211.714 94.3478H211.713Z"
          fill="black"
        />
        <path
          d="M215.775 85.3705C215.775 85.3705 219.601 79.5764 225.307 76.8416C230.851 74.1843 234.9 76.4474 235.001 76.5365C235.001 76.5365 232.153 81.7119 226.915 84.1918C219.797 87.5614 215.775 85.3705 215.775 85.3705Z"
          fill="black"
        />
        <path
          d="M217.001 77.4926C219.043 75.5823 219.195 70.3897 222.114 66.1754C226.186 60.2966 232.902 60.723 233.002 60.778C233.002 60.778 232.283 68.1021 227.192 72.5288C221.78 77.2332 217.001 77.4926 217.001 77.4926Z"
          fill="black"
        />
        <path
          d="M217.575 65.1475C217.575 65.1475 217.377 58.7817 219.065 53.9475C221.419 47.204 227.847 45.8801 227.959 45.9066C227.959 45.9066 229.001 53.1174 225.275 58.7305C221.315 64.6959 217.575 65.1473 217.575 65.1473L217.575 65.1475Z"
          fill="black"
        />
        <path
          d="M214.851 53.2174C214.851 53.2174 213.569 47.8733 214.219 42.797C215.198 35.1461 221.184 33.5264 221.298 33.5375C221.298 33.5375 223.35 40.6238 220.431 46.6908C217.328 53.1385 214.851 53.2174 214.851 53.2174Z"
          fill="black"
        />
        <path
          d="M209.579 40.4081C209.579 40.4081 207.494 35.5739 207.761 30.4637C208.132 23.3714 212.964 20.3751 213.079 20.371C213.079 20.371 217.738 24.549 215.062 33.5423C213.439 38.9976 209.579 40.4082 209.579 40.4082L209.579 40.4081Z"
          fill="black"
        />
        <path
          d="M202.857 29.8768C202.857 29.8768 199.759 25.1304 198.902 20.0846C198.098 15.3476 200.03 10.1868 200.461 9.71248C200.461 9.71248 205.503 11.916 205.314 20.3727C205.187 26.0606 202.857 29.8768 202.857 29.8768Z"
          fill="black"
        />
        <path
          d="M194.623 19.7145C194.623 19.7145 190.929 15.8022 189.967 10.4527C189.169 6.01891 189.972 1.20156 190.812 -0.000793457C190.812 -0.000793457 195.631 2.97659 195.981 10.5063C196.245 16.1894 195.005 18.8943 194.623 19.7144L194.623 19.7145Z"
          fill="black"
        />
        <path
          d="M188.65 13.4821C188.65 13.4821 181.61 13.7351 178.111 11.7147C174.195 9.45316 170.351 4.5444 170.048 3.11139C170.048 3.11139 176.214 0.422327 181.963 5.33425C186.302 9.04144 188.312 12.643 188.65 13.4821Z"
          fill="black"
        />
        <path
          d="M196.407 21.9292C196.407 21.9292 193.039 18.0081 187.253 16.9951C182.793 16.2143 179.364 16.9352 177.163 18.2109C177.163 18.2109 180.559 22.5385 187.068 23.1351C192.765 23.657 195.57 22.282 196.407 21.9292Z"
          fill="black"
        />
        <path
          d="M203.351 30.9698C203.351 30.9698 200.145 27.2088 194.359 26.1959C189.898 25.4151 187.757 26.216 185.555 27.4918C185.555 27.4918 188.268 31.6194 194.777 32.2158C200.473 32.7378 202.515 31.3227 203.351 30.9699L203.351 30.9698Z"
          fill="black"
        />
        <path
          d="M210.121 42.7637C210.121 42.7637 207.125 38.1282 201.709 35.8629C196.16 33.5415 193.309 34.6499 190.922 35.5361C191.683 36.6717 194.799 41.4054 199.849 42.8263C205.353 44.375 209.236 42.9703 210.121 42.7637Z"
          fill="black"
        />
        <path
          d="M214.119 53.238C214.119 53.238 211.464 48.4013 206.225 45.7564C200.857 43.0461 197.934 43.9489 195.489 44.6631C196.167 45.8499 198.934 50.793 203.869 52.5698C209.248 54.5061 213.221 53.3811 214.119 53.238Z"
          fill="black"
        />
        <path
          d="M216.221 65.6861C213.924 62.7572 212.675 59.6084 209.141 56.4745C204.787 52.6135 201.051 53.8444 198.516 54.0937C199.764 56.4254 201.638 60.1139 205.511 62.8929C210.15 66.2214 215.312 65.6609 216.221 65.6862V65.6861Z"
          fill="black"
        />
        <path
          d="M215.95 77.1552C213.997 73.9892 213.07 71.5212 209.911 68.0137C206.019 63.6925 202.329 64.4597 199.781 64.4253C200.759 66.8812 202.407 70.5551 205.944 73.7477C210.179 77.5715 215.05 77.029 215.95 77.1552Z"
          fill="black"
        />
        <path
          d="M213.434 86.3004C210.495 81.7887 210.866 80.7675 207.99 77.0266C204.901 73.0089 201 73.1193 198.464 72.8817C199.24 75.4078 200.401 78.8249 203.694 82.2656C207.205 85.935 210.857 85.4626 213.434 86.3004Z"
          fill="black"
        />
        <path
          d="M208.607 95.2619C206.755 91.2702 207.075 88.7776 205.376 85.9481C202.598 81.3204 199.875 81.1206 197.338 80.8832C199.361 86.6488 198.893 86.6754 201.12 90.1871C203.288 93.6042 206.232 94.0641 208.607 95.262V95.2619Z"
          fill="black"
        />
        <path
          d="M202.778 102.798C201.722 98.5284 202.794 97.2219 201.667 94.1229C199.824 89.0543 197.188 88.3433 194.744 87.6306C195.025 91.0332 194.6 93.1919 196.518 96.8799C197.872 99.4842 200.675 101.173 202.778 102.798Z"
          fill="black"
        />
        <path
          d="M191.055 94.4159C191.055 94.4159 188.938 98.4815 189.641 103.193C190.637 109.869 194.225 110.87 194.225 110.87C194.225 110.87 196.268 107.835 196.06 101.678C195.838 95.1015 191.055 94.4158 191.055 94.4158L191.055 94.4159Z"
          fill="black"
        />
        <path
          d="M183.41 101.217C183.41 101.217 181.291 105.333 181.754 110.074C182.349 116.15 185.694 117.671 185.694 117.671C185.694 117.671 187.802 114.279 187.892 109.159C188.029 101.342 183.41 101.217 183.41 101.217Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1017_239">
          <rect width="235" height="127" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ArrowSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 89 89"
      fill="none"
    >
      <g clip-path="url(#clip0_1053_387)">
        <path
          d="M87.7089 44.9667C85.9875 43.245 83.1962 43.245 81.4742 44.9667L71.1472 55.2935C69.4216 37.7237 56.4077 22.5513 38.2292 18.9353C24.8488 16.274 11.0489 20.4063 1.31556 29.9898C-0.419382 31.6983 -0.441132 34.4895 1.26706 36.2244C2.97555 37.9594 5.76679 37.9811 7.50143 36.2726C15.1516 28.7404 25.995 25.4924 36.5087 27.5833C50.8705 30.44 61.1299 42.4776 62.3931 56.3798L50.9792 44.9658C49.2578 43.2444 46.4663 43.2441 44.7446 44.9661C43.0228 46.6878 43.0228 49.4791 44.7446 51.2008L63.1094 69.5656C63.9702 70.4265 65.0985 70.8568 66.2266 70.8568C67.3546 70.8568 68.4832 70.4265 69.3441 69.5656L87.7083 51.2017C89.4306 49.48 89.4306 46.6884 87.7089 44.9667Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1053_387">
          <rect width="89" height="89" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};



import React, { useState } from 'react';
import { supabase } from '../createClient'; // Replace with your actual Supabase initialization file
import { Link, useNavigate } from 'react-router-dom';
import { Label, TextInput, Button } from 'flowbite-react';
import { Helmet } from 'react-helmet';
export default function RegisterPage() {
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const navigate = useNavigate();

    const handleRegister = async () => {
        const { user, error } = await supabase.auth.signUp({
            email: registerEmail,
            password: registerPassword,
        });

        if (error) {
            window.sa_event("failed_signup", {
                method: "Email",
                error: error.message,
            });
            console.error("Error creating account:", error.message);
            alert(error.error_description || error.message);
        } else {
            window.sa_event("new_signup", {
                method: "Email",
            });
            console.log("User signed up:", user);
            navigate("/dashboard");
            // Handle successful sign up; you can redirect or perform another action
        }
    };

    return (
        <div className="flex h-screen">
            <Helmet>
                <title>Register to Menumystic</title>
                <meta name="description" content="Register to Menumystic to finally understand every dish on the menu!" />
                <link rel="canonical" href="https://www.menumystic.com/register" />
            </Helmet>

            {/* Left Side */}
            <div className="flex-1 h-3/4 text-black flex flex-col items-center justify-center bg-white p-8 ">
                <h1 className="text-3xl text-center font-bold mb-2">Dive Into The Culinary World!</h1>
                <p className="text-xl mb-8 text-center">
                    Uncover the secrets behind each dish and transform your dining experiences. Say goodbye to culinary confusion!
                </p>
                
                <Label htmlFor="registerEmail" className="mb-4 text-lg">Email Address</Label>
                <TextInput 
                    id="registerEmail"
                    className="mb-4 w-80"
                    value={registerEmail} 
                    onChange={(e) => setRegisterEmail(e.target.value)} 
                />
                <Label htmlFor="registerPassword" className="mb-4 text-lg">Choose a Password</Label>
                <TextInput 
                    id="registerPassword" 
                    type="password" 
                    className="mb-4 w-80"
                    value={registerPassword} 
                    onChange={(e) => setRegisterPassword(e.target.value)} 
                />
                <Button color="primary" onClick={handleRegister} className="mb-2 text-white p-2 rounded w-80">Join Now</Button>
                <p className="text-gray-500">Already have an account? <Link to="/login"><span className="text-orange-700 hover:underline cursor-pointer">Login</span></Link></p>
            </div>

            {/* Right Side (Hidden on mobile using `lg:flex` and `hidden`) */}
            <div className="lg:flex items-center justify-center hidden flex-1 bg-gray-300">
                <img src="/iphone14.png" alt="iphone14 displaying menumystic app" className="h-4/5 object-cover" />
            </div>
        </div>
    );
}

import React from "react";
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
  return (
    <div className="tos-container bg-white p-8">
      <Helmet>
        <title>MenuMystic | Terms and Conditions</title>
        <meta name="description" content="Terms and Conditions for MenuMystic" />
        <meta name="keywords" content="menu, mystic, terms, service" />
        <link rel="canonical" href="https://www.menumystic.com/terms-and-conditions" />
      </Helmet>
      <h1 className="text-2xl font-bold text-orange-500 mb-6">
        Terms and Conditions for MenuMystic
      </h1>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-3">1. Acceptance of Terms</h2>
        <p className="text-black">
          By accessing or using the services of MenuMystic, you agree to be bound by these terms and conditions. If you disagree with any part of the terms, you may not access our services.
        </p>
      </section>

      <div className="space-y-4">
        <h2>2. Changes to Terms</h2>
        <p>
          MenuMystic reserves the right to change or modify these terms at any time, and by continuing to use our services, you agree to be bound by the revised terms.
        </p>

        <h2>3. User Accounts</h2>
        <p>
          Users are responsible for safeguarding their account credentials. Any unauthorized use of your account should be immediately reported to MenuMystic.
        </p>

        <h2>4. Prohibitions</h2>
        <p>
          Users are prohibited from using our services for any illegal or unauthorized purpose. Additionally, users are prohibited from introducing malicious software, conducting data breaches, or any activity that disrupts the service.
        </p>

        <h2>5. Termination of Service</h2>
        <p>
          MenuMystic reserves the right to terminate or suspend access to our service without prior notice or liability.
        </p>

        <h2>6. Limitation of Liability</h2>
        <p>
          MenuMystic shall not be liable for any direct or indirect damages resulting from the use or inability to use our services.
        </p>
      </div>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-3">7. Contact Information</h2>
        <p className="text-black">
          For any inquiries or clarifications regarding these terms, please email us at contact@732development.com or contact us by post.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;

import React, { useEffect, useState } from "react";
import {
  PricingCardBasic,
  PricingCardFree,
  PricingCardPremium,
} from "../components/PricingCards";
import { supabase } from "../createClient";
import axios from "axios";

// const base = "http://127.0.0.1:8080";
const base = "https://striped-guard-429700-f5.uc.r.appspot.com/"

const getUserId = async () => {
  const { data, error } = await supabase.auth.getSession();
  if (error) {
    console.error("Error getting user session:", error);
    throw error;
  }
  console.log(data.session.user.id);
  // console.log("Session:", data["user"]["id"]);
  return data ? data.session.user.id : null;
};

const UpgradePage = () => {
  const [plan, setPlan] = React.useState("free");
  const client = axios.create({
    // baseURL: "http://127.0.0.1:8080",
    baseURL: "https://striped-guard-429700-f5.uc.r.appspot.com/",
  });

  useEffect(() => {
    checkSubscriptionStatus();
  }, []);

  const getAuthToken = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (error) {
      console.error("Error getting user session:", error);
      throw error;
    }
    // console.log("Session:", data["session"]["access_token"]);
    return data ? data.session.access_token : null;
  };

  const checkSubscriptionStatus = async () => {
    try {
      window.sa_event("viewed_upgrade_page", {
        user_id: await getUserId(),
      });
      const token = await getAuthToken();
      const response = await client.post(
        "/get-subscription",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      // plan = accounts[0]['plan']
      // print(plan)
      // return jsonify(plan), 200
      console.log(response.data);

      if (response.status === 200) {
        setPlan(response.data);
      }
    } catch (error) {
      setPlan("monthly_free_plan")
      console.error("Error getting subscription status:", error);
    }
  };

  const [user, setUser] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      const userId = await getUserId();
      console.log(userId);
      setUser(userId);
    };
    getUser();
  }, []);
  return (
    <div className="bg-white min-h-screen p-2 sm:p-8">
      {/* <h1 className="flex flex-col justify-center py-[32px] px-[16px] items-center ">
        <span className="mb-2 text-2xl md:text-5xl font-bold text-gray-900 ">
          Upgrade to Unlock More!
        </span>
      </h1> */}
      <div className="p-3 sm:p-8 rounded-lg shadow-md mx-auto bg-gray-500">
        {plan === "monthly_free_plan" ? null : (
          <div className="mt-[20px] mb-[64px]">
            <h2 className="text-2xl font-bold text-white mb-4">
              Manage Your Account
            </h2>
            <p className="text-white">
              Access and manage your customer portal for personalized settings,
              billing details, and more.
            </p>
            <form method="post" action={base + "/create-customer-portal-session"}>
              {/* <input type="hidden" name="lookup_key" value="basic_monthly_plan" /> */}
              <input type="hidden" name="user_id" value={user} />
              {/* <input type="submit" value="Go to Customer Portal" /> */}
              <button
                type="submit"
                className="mt-4 bg-orange-500 text-white rounded-lg px-4 py-2 hover:bg-orange-600 transition duration-300 ease-in-out"
                >
                Go to Customer Portal
                </button>
            </form>
            {/* <button
              onClick={() => openCustomerPortal}
              className="mt-4 bg-orange-500 text-white rounded-lg px-4 py-2 hover:bg-orange-600 transition duration-300 ease-in-out"
            >
              Go to Customer Portal
            </button> */}
          </div>
        )}

        <div className="grid lg:grid-cols-2 lg:grid-rows-1 grid-rows-2 gap-[32px] sm:gap-[64px]  mx-auto max-w-7xl ">
          {plan === "monthly_premium_plan" ? <PricingCardBasic /> : null}
          {plan === "monthly_premium_plan" ? <PricingCardPremium /> : null}
          {plan === "monthly_free_plan" ? <PricingCardBasic /> : null}
          {plan === "monthly_free_plan" ? <PricingCardPremium /> : null}
        </div>
      </div>
    </div>
  );
};

export default UpgradePage;

import React from "react";
import { HiOutlineArrowRight } from "react-icons/hi";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <nav className="bg-gray-200 h-[96px] drop-shadow-md">
      <div className="flex text-black  items-center justify-between h-full  px-6 sm:px-8 lg:px-28 ">
        <Link to="/">
          <div className="flex ">
            <div className="place-self-center m-[8px] text-5xl z">🍝</div>
            <div className="place-self-center text-[18px] font-bold">
              Menu<span className="text-orange-500">Mystic</span>
            </div>
          </div>
        </Link>
        <div className="flex items-center">
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <a
                href="#how_it_works"
                className="  hover:text-orange-600 text-gray-900  px-3 py-2 rounded-md text-sm font-medium"
              >
                How it works
              </a>
              <a
                href="#pricing"
                className="  hover:text-orange-600 text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
              >
                Pricing
              </a>
              <Link
                to="/blog"
                className="  hover:text-orange-600 text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
              >
                Blog
              </Link>
              <a
                href="#faq"
                className="  hover:text-orange-600 text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
              >
                FAQ
              </a>
            </div>
          </div>
          <Link to="/dashboard">
            <Button color = "primary" className="ml-4 ">
              <p>Get started</p>
              <HiOutlineArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </Link>
        </div>
      </div>
    </nav>
  );
}

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import AppNavbar from "./components/AppNavbar";
import Footer from "./components/Footer";
import PrivateRoutes from "./pages/PrivateRoutes";
import DashboardMenu from "./pages/Menu";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import Dashboard from "./pages/Dashboard";
import { useState } from "react";
import { Flowbite } from "flowbite-react";
import Blog from "./pages/BlogPage";
import SubBlog from "./pages/SubBlogPage";
import BlogPostDetail from "./components/BlogPostDetails";
import PrivacyPolicy from "./pages/PrivacyPolicyPage";
import TermsAndConditions from "./pages/TermsAndConditionsPage";
import UpgradePage from "./pages/UpgradePage";

const Layout = ({ children }) => {
  const location = useLocation();
  const isDashboard = location.pathname.includes("/dashboard");

  const [upgradeModal, setUpgradeModal] = useState(false);

  const toggleModal = () => setUpgradeModal(!upgradeModal);
  const customTheme = {
    dark: false,
    theme: {
      button: {
        color: {
          primary: "text-white bg-orange-500 border border-transparent enabled:hover:bg-orange-600 focus:ring-4 focus:ring-orange-300 ",
        },
      },
    },
  };
  return (
    <>
    <Flowbite theme={customTheme}>
      {isDashboard ? <AppNavbar /> : <Navbar />}

      {children}
      {!isDashboard && <Footer />}
      </Flowbite>
    </>
    
  );
};

export default function Router() {
  

  return (
    
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<SubBlog />} />
          <Route path="/blog/posts/:slug" element={<BlogPostDetail />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard/upgrade" element={<UpgradePage />} />
            <Route path="/dashboard/menu" element={<DashboardMenu />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </Layout>
    </BrowserRouter>
    
  );
}

import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container bg-white p-8">
      <Helmet>
        <title>MenuMystic | Privacy Policy</title>
        <meta name="description" content="Privacy Policy for MenuMystic" />
        <meta name="keywords" content="menu, mystic, privacy, policy" />
        <link
          rel="canonical"
          href="https://www.menumystic.com/privacy-policy"
        />
      </Helmet>
      <h1 className="text-2xl font-bold text-orange-500 mb-6">
        Privacy Policy for MenuMystic
      </h1>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-3">1. Introduction</h2>
        <p className="text-black">
          Welcome to MenuMystic. We are committed to protecting your personal
          information and your right to privacy. If you have any questions or
          concerns about this privacy notice, or our practices regarding your
          personal information, please contact us at contact@732development.com.
        </p>
      </section>
      <div className="space-y-4">
        <h2>2. Information We Collect</h2>
        <p>
          We collect personal information that you provide to us such as name,
          address, contact information, passwords, and security data, payment
          information, and social media login data.
        </p>

        <h2>3. How We Use Your Information</h2>
        <p>
          We use personal information collected via our website for various
          purposes, such as:
          <ul>
            <li>To facilitate account creation and login</li>
            <li>To send administrative information</li>
            <li>Fulfill and manage orders</li>
            <li>Post testimonials</li>
            <li>Deliver targeted advertising</li>
            <li>Request feedback</li>
          </ul>
        </p>

        <h2>4. Will Your Information be Shared?</h2>
        <p>
          We only share information with your consent, to comply with laws,
          provide you with services, protect your rights, or fulfill business
          obligations.
        </p>

        <h2>5. How Long We Keep Your Information</h2>
        <p>
          We keep your information for as long as necessary to fulfill the
          purposes outlined in this privacy policy, unless otherwise required by
          law.
        </p>

        <h2>6. How We Keep Your Information Safe</h2>
        <p>
          We use administrative, logical, and physical security measures to
          protect your personal information. However, no method of transmission
          over the internet is 100% secure.
        </p>

        <h2>7. Do We Collect Information from Minors?</h2>
        <p>
          We do not knowingly collect or solicit data from or market to children
          under 18 years of age.
        </p>

        <h2>8. Your Privacy Rights</h2>
        <p>
          You may review, change, or terminate your account at any time. If you
          are a resident in a region with laws governing data collection, you
          may have rights that you can exercise regarding your personal
          information.
        </p>

        <h2>9. Updates to this Policy</h2>
        <p>
          We may update this privacy policy from time to time. The updated
          version will be indicated by an updated "Revised" date.
        </p>
      </div>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-3">10. How to Contact Us</h2>
        <p className="text-black">
          If you have questions or comments about this privacy policy, you may
          email us at contact@732development.com or contact us by post.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;

import { useLocation, useNavigate } from "react-router-dom";
import mockData from "../mockData";
import { Button, Flowbite, Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import { CustomFlowbiteTheme } from "flowbite-react";
import { HiCamera, HiInformationCircle } from "react-icons/hi";
import axios from "axios";
import { supabase } from "../createClient";
import { BarLoader } from "react-spinners";
import { FaCheck } from "react-icons/fa";


function DisplayText(props) {
  return <span style={{whiteSpace: 'pre-line'}}>{props.text}</span>;
}

const getAuthToken = async () => {
  const { data, error } = await supabase.auth.getSession();
  if (error) {
    console.error("Error getting user session:", error);
    throw error;
  }
  // console.log("Session:", data["session"]["access_token"]);
  return data ? data.session.access_token : null;
};
export const Stage = ({ stageNumber, currentStage, stageName, isLoading }) => {
  return (
    <div className="flex items-center space-x-2">
      {currentStage > stageNumber && (
        <FaCheck className="text-green-500" size={20} />
      )}
      {isLoading && (
        <BarLoader width={20} height={4} color={"#edab55"} loading={true} />
      )}
      <span
        className={`text-md text-black ${
          currentStage >= stageNumber ? "font-bold" : "font-normal"
        }`}
      >
        {stageName}
      </span>
    </div>
  );
};

export default function DashboardMenu(props) {
  const client = axios.create({
    // baseURL: "http://127.0.0.1:8080",
    baseURL: "https://striped-guard-429700-f5.uc.r.appspot.com/",
  });

  const location = useLocation();
  const navigate = useNavigate();
  const [localImage, setLocalImage] = useState(null);
  const [localCoordinates, setLocalCoordinates] = useState(null);
  const [restaurantId, setRestaurantId] = useState(null);
  const [displayDetails, setDisplayDetails] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [modal, setModal] = useState("loading");
  const [menu, setMenu] = useState(mockData.meals);
  const [selectedImageSrc, setSelectedImageSrc] = useState(null);
  const [selectedCorners, setSelectedCorners] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [plan , setPlan] = useState(null);
  const [stage, setStage] = useState(0);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      setSelectedImage(file);
      setSelectedImageSrc(URL.createObjectURL(file)); // Convert File to URL
      setModal("crop_image");
    }
  };

  const handleImageCrop = async () => {
    if (selectedCorners.length !== 4) {
      alert("Please select 4 corners");
      return;
    }
    const { data, error } = await supabase.auth.getSession();
    if (!data || !data["session"]) {
      console.log("not logged in");
      // setModal("create_account");
      navigate("/login");
    } else {
      console.log("logged in");
      const token = await getAuthToken();
      if (!token) {
        alert("No token found. Please log in again.");
        return;
      }
      const formData = new FormData();
      formData.append("image", selectedImage);
      formData.append("coordinates", JSON.stringify(selectedCorners));
      formData.append("restaurant_id", restaurantId);
      const response = await client.post("upload-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.message);
      setModal("loading");
      setLocalImage(response.data["image"]);
      setLocalCoordinates(response.data["coordinates"]);
    }
  };

  const loadMenu = async () => {
    try {
      console.log("in load menu");
      const token = await getAuthToken();
      if (!token) {
        alert("No token found. Please log in again.");
        return;
      }

      console.log("Restaurant ID:", restaurantId);
      // console.log("Token:", token);

      const formData = new FormData();
      formData.append("restaurant_id", restaurantId);

      const response = await client.post("get-meals", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);

      setMenu(response.data[0]);
      setPlan(response.data[1].plan);
      setModal(null);
    } catch (error) {
      console.error("Error loading existing menu:", error);
      setModal("error");
    }
  };
  const handleStages = async (
  ) => {
    
  
    const handleGeneratePromise = generateMenu();
    const stagePromises = Array.from({ length: 4 }, (_, i) =>
      new Promise((resolve) => setTimeout(resolve, i * 15000)).then(() => {
        console.log("Stage ", i + 1);
        setStage((prevStage) => Math.max(prevStage, i + 1));
      })
    );
    // setStage(4);
    await Promise.all([...stagePromises, handleGeneratePromise]);
    // await Promise.all([...stagePromises]);
    setStage(4);
    
  };

  const generateMenu = async () => {
    try {
      console.log("Coordinates:", localCoordinates);
      console.log("Image File:", localImage);
      const token = await getAuthToken();
      if (!token) {
        alert("No token found. Please log in again.");
        return;
      }
      const formData = new FormData();
      // formData.append("image", image);
      formData.append("image", localImage);
      // formData.append("coordinates", JSON.stringify(coordinates));
      formData.append("coordinates", JSON.stringify(localCoordinates));

      const response = await client.post("upload-initial-scan", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data["message"] === "upgrade_for_more") {
        setModal("upgrade");
      } else {
        window.sa_event("menu_generated", {
          restaurant_id: response.data["restaurant_id"],
        });
      // console.log(response.data.message);
        setRestaurantId(response.data["restaurant_id"]);
      }
      // setMenu(response.data["meals"]);
    } catch (error) {
      window.sa_event("menu_generation_failed", {
        error: error.message,
      });
      console.error("Error uploading coordinates:", error);
      setModal("error")
    }
  };
  // Set up initial state from local storage or location state.
  useEffect(() => {
    const savedRestaurantId = localStorage.getItem("restaurantId");
    console.log("Saved Restaurant ID:", savedRestaurantId);

    const clearLocationState = () => {
      console.log("Clearing location state");
      navigate("/dashboard/menu", {
        replace: true,
        state: { restaurant_id: restaurantId },
      });
    };

    if (savedRestaurantId) {
      console.log("Found saved restaurant ID");
      if (location.state) {
        console.log("Found location state");
        if (location.state.image && location.state.coordinates) {
          console.log("Found image and coordinates in location state");
          setLocalImage(location.state.image);
          setLocalCoordinates(location.state.coordinates);
          clearLocationState();
        }
        if (location.state.restaurant_id) {
          console.log("Found restaurant ID in location state");
          setRestaurantId(location.state.restaurant_id);
          clearLocationState();
        }
      } 
      else {
        console.log("Loading menu for restaurant ID:", savedRestaurantId);
        setRestaurantId(savedRestaurantId);
      }
      
    } else if (location.state) {
      console.log("Found location state");
      if (location.state.image && location.state.coordinates) {
        console.log("Found image and coordinates in location state");
        setLocalImage(location.state.image);
        setLocalCoordinates(location.state.coordinates);
        clearLocationState();
      }
      if (location.state.restaurant_id) {
        console.log("Found restaurant ID in location state");
        setRestaurantId(location.state.restaurant_id);
        clearLocationState();
      }
    }
  }, []);

  // When restaurantId is set or updated, load the menu.
  useEffect(() => {
    if (restaurantId) {
      console.log("Loading menu for restaurant ID:", restaurantId);
      loadMenu();
    }
  }, [restaurantId]);

  // When localImage and localCoordinates are set or updated, generate the menu.
  useEffect(() => {
    if (localImage && localCoordinates && !restaurantId) {
      console.log("Generating menu based on image and coordinates");
      // generateMenu();
      handleStages();
    }
  }, [localImage, localCoordinates]);

  // Save restaurantId to local storage when it changes.
  useEffect(() => {
    if (restaurantId) {
      console.log("Saving restaurant ID to local storage");
      localStorage.setItem("restaurantId", restaurantId);
    }
  }, [restaurantId]);

  const customTheme = {
    theme: {
      modal: {
        root: {
          show: {
            on: "flex  bg-white bg-opacity-50 backdrop-blur-md",
          },
        },
      },
    },
  };

  return (
    <div className="min-h-screen bg-white">
      <h1 className="flex flex-col justify-center py-[32px] px-[16px] items-center ">
        <span className="mb-2 text-2xl md:text-5xl font-bold text-gray-900 ">
          Explained Menu
        </span>
        <p className="text-gray-600 text-lg md:text-xl">
          Detailed insights into each dish.
        </p>
      </h1>

      <div className="px-[16px] mb-8 grid gap-[16px] xl:grid-cols-3 sm:grid-cols-2 grid-cols-1">
        {menu.map((meal) => (
          <div key={meal.id} className="flex meal-container">
            <div className="flex bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 w-full">
              <div
                style={{
                  backgroundImage: `url(${meal.image_url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="flex-shrink-0 w-1/3 md:w-1/4 h-full rounded-l-lg"
              ></div>
              <div className="flex flex-col justify-between p-4 leading-normal flex-grow">
                <h5 className="mb-2 text-xl md:text-2xl font-bold tracking-tight text-gray-900 ">
                  <p>{meal.name}</p>
                </h5>
                <p className="mb-3 text-xs md:text-sm font-normal text-gray-700 ">
                  {meal.short_description}
                </p>
                <div className="flex flex-col md:flex-row items-center space-y-2 space-x-0 md:space-x-2 md:space-y-0">
                  <Button
                    color="primary"
                    className="w-full md:w-1/2 h-full "
                    onClick={() => {
                      setSelectedMeal(meal);
                      setDisplayDetails(true);
                    }}
                  >
                    <div className="text-xs md:text-md">Details</div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* {/* 
          <div className="flex items-center justify-center text-md md:text-lg">
            
          </div> */}
        {/*  */}
        { plan === "free" && (
         <div className="flex meal-container">
          <div className="flex bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 w-full">
            {/* <HiInformationCircle className="flex-shrink-0 w-1/3 md:w-1/4 h-full rounded-l-lg" /> */}

            <div className="flex flex-col justify-between p-4 py-10 leading-normal flex-grow">
              <h5 className="mb-2 text-xl md:text-2xl font-bold tracking-tight text-gray-900 ">
                <p>Upgrade your plan</p>
              </h5>
              <p className="mb-3 text-xs md:text-sm font-normal text-gray-700 ">
                You can see up to 5 dishes with the free plan. Upgrade to see the rest.
              </p>
              <div className="flex flex-col md:flex-row items-center space-y-2 space-x-0 md:space-x-2 md:space-y-0">
                <Button
                  color="primary"
                  className="w-full md:w-1/2 h-full "
                  onClick={() => {

                    navigate("/dashboard/upgrade");
                  }}
                >
                  <div className="text-xs md:text-md">Upgrade</div>
                </Button>

                </div>
                </div>
                </div>




        </div>
        )
        }
      </div>

      <Modal
        show={displayDetails}
        size="md"
        popup
        onClose={() => setDisplayDetails(false)}
      >
        <Modal.Header>
          <h3 className="sm:text-md md:text-xl font-bold text-gray-900 ">
            {selectedMeal?.name}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-4">
            <div className="flex flex-col items-center">
              <img
                src={selectedMeal?.image_url}
                alt={selectedMeal?.name}
                className="sm:w-1/2 md:w-full h-64 object-cover mb-4"
              />
            </div>
            <p className="text-sm md:text-md font-medium text-gray-800 ">
          <DisplayText text={selectedMeal?.long_description} />
              {/* {} */}
            </p>
            
                <div className="space-y-2">
                  <h4 className="font-semibold text-md md:text-lg">
                    Wine Pairing:
                  </h4>
                  {selectedMeal?.wine_pairing ? (
                  <p className="text-sm md:text-md font-normal text-gray-700 ">
                    {selectedMeal?.wine_pairing}
                  </p>
                  )
                : (

                  <p className="text-sm md:text-md font-normal text-gray-700 ">
                    Upgrade to Basic or Premium Plan
                  </p>
                )
                }
                </div>
           
            
                <div className="space-y-2">
                  <h4 className="font-semibold text-md md:text-lg">
                    Dessert Pairing:
                  </h4>
                  {selectedMeal?.dessert_pairing ? (
                  <p className="text-sm md:text-md font-normal text-gray-700 ">
                    {selectedMeal?.dessert_pairing}
                  </p>
                   )
                    : (
                      <p className="text-sm md:text-md font-normal text-gray-700 ">
                    Upgrade to Premium Plan
                  </p>
                    )
                  }
                </div>
             
          </div>
        </Modal.Body>
      </Modal>

      <Flowbite theme={customTheme}>
        <Modal show={modal === "loading"} size="sm">
          {/* <Modal.Header /> */}
          <Modal.Body>
            <div className="flex text-black h-full flex-col items-center space-y-2 justify-center">
              {/* <h5 className="font-bold text-3xl">Hang tight! It may take 1 or 2 minutes to process your image.</h5> */}
              <h5 className="font-bold text-3xl">Working on it...</h5>
              {/* <Spinner color="warning" size="xl" /> */}
              {/* <p className="text-gray-900 text-center">
                Hang tight! It may take 1 or 2 minutes to process your image.
              </p> */}
              <p className="text-gray-900 text-center">
              Continue your conversation, we'll be right back!
              </p>
              <div className="flex flex-col space-y-4 mb-4">
                    <Stage
                      stageNumber={1}
                      currentStage={stage}
                      stageName="Scanning Text"
                      isLoading={stage === 1}
                    />
                    <Stage
                      stageNumber={2}
                      currentStage={stage}
                      stageName="Extracting Ingredients"
                      isLoading={stage === 2}
                    />
                    <Stage
                      stageNumber={3}
                      currentStage={stage}
                      stageName="Generating Pairings"
                      isLoading={stage === 3}
                    />
                    <Stage
                      stageNumber={4}
                      currentStage={stage}
                      stageName="Getting Images"
                      isLoading={stage === 4}
                    />
                  </div>
            </div>
          </Modal.Body>
        </Modal>
      </Flowbite>
      <Flowbite theme={customTheme}>
        <Modal show={modal === "upgrade"} size="sm">
          {/* <Modal.Header /> */}
          <Modal.Body>
            <div className="flex text-black h-full flex-col items-center space-y-2 justify-center">
              <h5 className="font-bold text-3xl">Upgrade your plan!</h5>
              
              <p className="text-gray-900 text-center">
                You have reached your limit for this week. Please upgrade your plan to add more scans.
              </p>
              <Button
                color="primary"
                className="bg-orange-500 hover:bg-orange-600 rounded-md text-white w-full md:w-1/2 h-full "
                onClick={() => {
                  setModal("none");
                  navigate("/dashboard/upgrade")
                }}
              >
                <div className="text-md md:text-lg">Upgrade</div>
              </Button>

            </div>
          </Modal.Body>
        </Modal>
      </Flowbite>
      <Flowbite theme={customTheme}>
        <Modal show={modal === "error"} size="sm">
          {/* <Modal.Header /> */}
          <Modal.Body>
            <div className="flex text-black h-full flex-col items-center space-y-2 justify-center">
              <h5 className="font-bold text-3xl">Oops!</h5>
              {/* <Spinner color="warning" size="xl" /> */}
              <p className="text-gray-900 text-center">
                Something went wrong. Please try again.
              </p>
              <Button
                color="primary"
                className="w-full md:w-1/2 h-full "
                onClick={() => {
                  setModal("none");
                  navigate("/dashboard/menu", { replace: true, state: {} });
                  navigate("/dashboard");
                }}
              >
                <div className="text-xs md:text-md">Go back</div>
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </Flowbite>
    </div>
  );
}

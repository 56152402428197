"use client";

import { Accordion } from "flowbite-react";

export default function DefaultAccordion() {
  return (
    <Accordion className="text-black">
      <Accordion.Panel>
        <Accordion.Title>What is MenuMystic?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500">
            MenuMystic is your culinary companion for dining out. By simply
            scanning a restaurant's menu, the tool provides comprehensive
            explanations for each meal. No more guessing or awkwardly asking the
            waiter about every dish!
          </p>
          <p className="text-gray-500">
            But that's not all. MenuMystic enhances your dining experience by
            offering wine and dessert pairings tailored to your selected dishes.
            Elevate your meals and become a true food connoisseur with the help
            of MenuMystic.
          </p>
          <a
            className="text-orange-600 hover:underline"
            href="https://menumystic.com/docs/getting-started/introduction/" // Change the link to your actual documentation or relevant page
          >
            Learn more about how to get started with MenuMystic.
          </a>
        </Accordion.Content>
      </Accordion.Panel>

      <Accordion.Panel>
        <Accordion.Title>Is the tool for free?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500">
            Yes, we offer a Free plan that allows users to scan up to 3 menus
            per week and get explanations for 5 meals per menu. It's perfect for
            occasional diners and those wanting to try the service.
          </p>
          <p className="text-gray-500">
            For more frequent usage and additional features like wine pairing,
            dessert recommendations, and unlimited scans, you can check out our
            <a className="text-orange-600 hover:underline " href="/pricing">
              {" "}
              affordable paid plans.
            </a>
          </p>
        </Accordion.Content>
      </Accordion.Panel>

      <Accordion.Panel>
        <Accordion.Title>How does MenuMystic work?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500">
            MenuMystic utilizes advanced AI and image recognition to scan and
            interpret the dishes listed on any menu. Once a dish is recognized,
            the tool fetches a comprehensive explanation and, depending on your
            subscription, offers wine and dessert pairings.
          </p>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title>Do I need to download an app?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 ">
            No, MenuMystic works directly through your browser, meaning there's
            no need to download any additional software or apps. Just access our
            site, and you're ready to scan and explore!
          </p>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title>
          How accurate are the wine and dessert pairings?
        </Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500">
            Our wine and dessert pairings are based on traditional culinary
            principles combined with AI learning. While individual preferences
            may vary, our suggestions aim to complement the flavors of your
            chosen dish.
          </p>
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { Stage, Layer, Image, Circle, Line } from "react-konva";
import useImage from "use-image";

const AdjustableImage = ({ src, onCornersChange }) => {
  const [img] = useImage(src);
  const margin = 150;

  const [scale, setScale] = useState(1);

  const [corners, setCorners] = useState([
    { x: margin, y: margin },
    { x: margin, y: margin },
    { x: margin, y: margin },
    { x: margin, y: margin },
  ]);

  useEffect(() => {
    if (img) {
      let computerDeviceScale = 0.8;
      if (window.innerWidth < window.innerHeight) {
        computerDeviceScale = 1;
      }

      const scaleX = window.innerWidth / img.width;
      const scaleY = window.innerHeight / img.height;
      const maxScaleY = 2 / 3; // New line to ensure image height doesn't exceed 2/3 of screen height
      const scaleValue =
        computerDeviceScale * Math.min(scaleX, scaleY * maxScaleY, 1);
      setScale(scaleValue);

      const initialCorners = [
        { x: margin * scaleValue, y: margin * scaleValue },
        { x: (img.width - margin) * scaleValue, y: margin * scaleValue },
        {
          x: (img.width - margin) * scaleValue,
          y: (img.height - margin) * scaleValue,
        },
        { x: margin * scaleValue, y: (img.height - margin) * scaleValue },
      ];
      setCorners(initialCorners);
    }
  }, [img]);
  const constrainPosition = (pos) => {
    return {
      x: Math.min(Math.max(pos.x, 0), img.width * scale),
      y: Math.min(Math.max(pos.y, 0), img.height * scale),
    };
  };
  const handleDragMove = (e, index) => {
    const newCorners = [...corners];
    newCorners[index] = { x: e.target.x(), y: e.target.y() };
    const unscaledCorners = newCorners.map((corner) => ({
      x: corner.x / scale,
      y: corner.y / scale,
    }));

    setCorners(newCorners);
    onCornersChange && onCornersChange(unscaledCorners);
  };

  return (
    // <div className=" bg-black">

    <Stage
      width={img ? img.width * scale : 0}
      height={img ? img.height * scale : 0}
    >
      <Layer>
        <Image image={img} scale={{ x: scale, y: scale }} />

        {/* Gray out area outside of shape */}
        <Line
          points={corners.flatMap((corner) => [corner.x, corner.y])}
          closed
          //   fill="gray"
          opacity={0.6}
        />

        {/* Drawing red lines between corners */}
        <Line
          points={corners.flatMap((corner) => [corner.x, corner.y])}
          closed
          stroke="orange"
          strokeWidth={2}
        />

        {corners.map((corner, index) => (
          <Circle
            key={index}
            x={corner.x}
            y={corner.y}
            draggable
            radius={10}
            fill="orange"
            onDragMove={(e) => handleDragMove(e, index)}
            dragBoundFunc={constrainPosition}
          />
        ))}
      </Layer>
    </Stage>
    // </div>
  );
};

export default AdjustableImage;

"use client";

import { Card } from "flowbite-react";
import { HiCheckCircle } from "react-icons/hi";
import { Form, Link } from "react-router-dom";
import axios from "axios";
import { supabase } from "../createClient";
import { useEffect, useState } from "react";
const client = axios.create({
  // baseURL: "http://127.0.0.1:8080",
  baseURL: "https://striped-guard-429700-f5.uc.r.appspot.com/",
});

const base = "https://striped-guard-429700-f5.uc.r.appspot.com/";
// const base = "http://127.0.0.1:8080"

const getUserId = async () => {
  const { data, error } = await supabase.auth.getSession();

  if (error) {
    console.log(error);
    return null;
  }

  if (data && data.session && data.session.user) {
    return data.session.user.id;
  } else {
    return null;
  }
};



export function PricingCardFree({ landing = false }) {

  return (
    <Card>
      <h5 className="mb-2 sm:mb-4 text-xl font-medium text-gray-500 sm:text-2xl">
        Free plan
      </h5>

      <div className="flex items-baseline text-gray-900">
        <span className="text-2xl sm:text-3xl font-semibold">$</span>
        <span className="text-4xl sm:text-5xl font-extrabold tracking-tight">
          0
        </span>
        <span className="ml-1 text-lg sm:text-xl font-normal text-gray-500">
          /month
        </span>
      </div>

      <ul className="my-1 sm:my-7 space-y-3 sm:space-y-5">
        <li className="flex space-x-2 sm:space-x-3">
          <HiCheckCircle className="text-green-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            3 menu scans per week
          </span>
        </li>

        <li className="flex space-x-2 sm:space-x-3">
          <HiCheckCircle className="text-green-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            Explanations for 5 meals per menu
          </span>
        </li>

        <li className="flex space-x-2 sm:space-x-3 line-through decoration-gray-500">
          <HiCheckCircle className="text-gray-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            Wine pairing recommendations
          </span>
        </li>

        <li className="flex space-x-2 sm:space-x-3 line-through decoration-gray-500">
          <HiCheckCircle className="text-gray-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            Dessert recommendations
          </span>
        </li>

        <li className="flex space-x-2 sm:space-x-3 line-through decoration-gray-500">
          <HiCheckCircle className="text-gray-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            Saved favorites
          </span>
        </li>
      </ul>

      {landing ? (
        <Link
          to="/dashboard/upgrade"
          className="inline-flex w-full justify-center rounded-lg bg-orange-500 px-4 sm:px-5 py-2 text-center text-sm font-medium text-white hover:bg-orange-600 focus:outline-none focus:ring-4 focus:ring-cyan-200"
        >
          Choose plan
        </Link>
      ) : (
        <button
          // Uncomment the below line when you want to add functionality to the button
          // onClick={() => upgrade("")}
          className="inline-flex w-full justify-center rounded-lg bg-orange-500 px-4 sm:px-5 py-2 text-center text-sm font-medium text-white hover:bg-orange-600 focus:outline-none focus:ring-4 focus:ring-cyan-200"
        >
          Choose plan
        </button>
      )}
    </Card>
  );
}

export function PricingCardBasic({ landing = false }) {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      const userId = await getUserId();
      if (userId == null) {
        return;
      }
      console.log(userId);
      setUser(userId);
    };
    getUser();
  }, []);
  return (
    <Card>
      <h5 className="mb-2 sm:mb-4 text-xl font-medium text-gray-500 sm:text-2xl">
        Basic plan
      </h5>

      <div className="flex items-baseline text-gray-900">
        <span className="text-2xl sm:text-3xl font-semibold">$</span>
        <span className="text-4xl sm:text-5xl font-extrabold tracking-tight">
          5
        </span>
        <span className="ml-1 text-lg sm:text-xl font-normal text-gray-500">
          /month
        </span>
      </div>

      <ul className="my-1 sm:my-7 space-y-3 sm:space-y-5">
        <li className="flex space-x-2 sm:space-x-3">
          <HiCheckCircle className="text-green-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            5 menu scans per week
          </span>
        </li>

        <li className="flex space-x-2 sm:space-x-3">
          <HiCheckCircle className="text-green-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            Unlimited meal explanations
          </span>
        </li>

        <li className="flex space-x-2 sm:space-x-3">
          <HiCheckCircle className="text-green-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            Wine pairing recommendations
          </span>
        </li>

        <li className="flex space-x-2 sm:space-x-3 line-through decoration-gray-500">
          <HiCheckCircle className="text-gray-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            Dessert recommendations
          </span>
        </li>

        <li className="flex space-x-2 sm:space-x-3 line-through decoration-gray-500">
          <HiCheckCircle className="text-gray-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            Saved favorites
          </span>
        </li>
      </ul>

      {landing ? (
        <Link
          to="/dashboard/upgrade"
          className="inline-flex w-full justify-center rounded-lg bg-orange-500 px-4 sm:px-5 py-2 text-center text-sm font-medium text-white hover:bg-orange-600 focus:outline-none focus:ring-4 focus:ring-cyan-200"
        >
          Choose plan
        </Link>
      ) : (
        <form method="post" action={base + "/create-checkout-session"}>
          {/* <input type="hidden" name="lookup_key" value="monthly_test_plan" /> */}
          <input type="hidden" name="lookup_key" value="monthly_basic_plan" />
          <input type="hidden" name="user_id" value={user} />
          <input type="submit" value="Upgrade" />

        </form>
      )}
    </Card>
  );
}

export function PricingCardPremium({ landing = false }) {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      const userId = await getUserId();
      if (userId == null) {
        return;
      }
      console.log(userId);
      setUser(userId);
    };
    getUser();
  }, []);
  return (
    <Card>
      <h5 className="mb-2 sm:mb-4 text-lg sm:text-xl font-medium text-gray-500">
        Premium plan
      </h5>

      <div className="flex items-baseline text-gray-900">
        <span className="text-2xl sm:text-3xl font-semibold">$</span>
        <span className="text-4xl sm:text-5xl font-extrabold tracking-tight">
          8
        </span>
        <span className="ml-1 text-lg sm:text-xl font-normal text-gray-500">
          /month
        </span>
      </div>

      <ul className="my-1 sm:my-7 space-y-3 sm:space-y-5">
        <li className="flex space-x-2 sm:space-x-3">
          <HiCheckCircle className="text-green-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            Unlimited weekly menu scans
          </span>
        </li>

        <li className="flex space-x-2 sm:space-x-3">
          <HiCheckCircle className="text-green-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            Unlimited meal explanations
          </span>
        </li>

        <li className="flex space-x-2 sm:space-x-3">
          <HiCheckCircle className="text-green-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            Wine pairing recommendations
          </span>
        </li>

        <li className="flex space-x-2 sm:space-x-3 decoration-gray-500">
          <HiCheckCircle className="text-green-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            Dessert recommendations
          </span>
        </li>

        <li className="flex space-x-2 sm:space-x-3 decoration-gray-500">
          <HiCheckCircle className="text-green-500" />
          <span className="text-sm sm:text-base font-normal leading-tight text-gray-500">
            Saved favorites
          </span>
        </li>
      </ul>

      {landing ? (
        <Link
          to="/dashboard/upgrade"
          className="inline-flex w-full justify-center rounded-lg bg-orange-500 px-4 sm:px-5 py-2 text-center text-sm font-medium text-white hover:bg-orange-600 focus:outline-none focus:ring-4 focus:ring-cyan-200"
        >
          Choose plan
        </Link>
      ) : (
        <form method="post" action={base + "/create-checkout-session"}>
          {/* <input type="hidden" name="lookup_key" value="monthly_test_plan" /> */}
          <input type="hidden" name="lookup_key" value="monthly_premium_plan" />
          <input type="hidden" name="user_id" value={user} />
          <input type="submit" value="Upgrade" />

        </form>
      )}
    </Card>
  );
}

import React, { useState, useEffect, Suspense } from "react";
import { supabase } from "../createClient";
import { Helmet } from "react-helmet";
import { Button, Card, Flowbite } from "flowbite-react";
import { useNavigate, Link } from "react-router-dom";

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const navigate = useNavigate();
  const customTheme = {
    theme: {
      card: {
        img: {
          horizontal: {
            off: "rounded-t-lg w-full h-40 object-cover",
          },
        },
      },
    },
  };

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    console.log("Fetching blog posts...");
    let { data: posts, error } = await supabase.from("food").select("*");
    console.log(posts);

    if (error) console.log("Error: ", error);
    else setBlogPosts(posts);
  };

  return (
    <div className="flex flex-col items-center p-6 w-full space-y-6 bg-gray-100 min-h-screen">
      <Helmet>
        <title>Blog | MenuMystic!</title>
        <meta
          name="description"
          content="MenuMystic is a free app that helps you understand the menu at any restaurant. No more guesswork! Understand every dish's name and order precisely what you crave."
        />
        <link rel="canonical" href="https://www.menumystic.com/blog" />
      </Helmet>

      <h1 className="text-5xl font-bold mt-10 mb-10 text-gray-900 bg-clip-text text-transparent bg-gradient-to-r from-[#FF6E00] to-[#FFC700]">
        Here is our
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#FF6E00] to-[#FFC700]">
          {" "}
          blog about food and cuisine around the world!
        </span>
      </h1>

      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full px-6">
        <Suspense fallback={<div>Loading...</div>}>
          <Flowbite theme={customTheme}>
            {blogPosts.map((post, index) => (
              <Card key={index} imgAlt={post.name} imgSrc={post.image_url} className="hover:shadow-lg transition-shadow duration-300">
                <h3 className="text-2xl font-bold mb-2">{post.name}</h3>
                <p className="text-gray-700 mb-4">{post.description}</p>
                <Link
                  to={`/blog/${post.slug}`}
                  className="text-[#FF6E00] hover:text-[#FFC700] font-bold"
                >
                  Read More
                </Link>
              </Card>
            ))}
          </Flowbite>
        </Suspense>
      </div>
    </div>
  );
};

export default Blog;

const mockData = {
    meals: [
        {
            id: 1,
            name: "Pasta Carbonara",
            short_description: "An authentic Roman delicacy showcasing the rich symphony between pancetta and creamy pasta.",
            long_description: "Dive into the culinary history of Rome with every bite. This dish stands as a testament to simplicity, emphasizing the robust flavors of its few ingredients. While it's tempting to add cream, true carbonara relies on eggs and pecorino cheese for its iconic creamy texture.",
            image_url: "https://www.insidetherustickitchen.com/wp-content/uploads/2020/03/Spaghetti-alla-Carbonara-square-Inside-the-Rustic-Kitchen.jpg",
            wine_pairing: "A dry white wine like Pinot Grigio",
            dessert_pairing: "Tiramisu"
        },
        {
            id: 2,
            name: "Sushi Platter",
            short_description: "A curated selection of the ocean's finest, wrapped in traditions and served with grace.",
            long_description: "Each roll is a journey through the shores of Japan. Sushi is not just food, but an art form, with each piece reflecting the passion and expertise of its creator. With fresh fish, perfectly seasoned rice, and accompaniments that elevate every bite, this platter is a symphony of flavors and textures.",
            image_url: "https://sushisushi.com/wp-content/uploads/2019/09/Sushi-Platter-Sushi-Catering-1024x1024.jpg",
            wine_pairing: "A crisp sake or light white wine",
            dessert_pairing: "Green tea ice cream"
        },
        {
            id: 3,
            name: "Chicken Tikka Masala",
            short_description: "India's culinary gift to the world, merging spicy chicken with a dreamy creamy sauce.",
            long_description: "Beyond being a dish, Chicken Tikka Masala is an experience. The marinated chicken, grilled to perfection, swims in a lusciously creamy tomato-based sauce. Each bite is an orchestra of spices, echoing stories of ancient Indian kitchens.",
            image_url: "https://www.allrecipes.com/thmb/1ul-jdOz8H4b6BDrRcYOuNmJgt4=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/239867chef-johns-chicken-tikka-masala-ddmfs-3X4-0572-e02a25f8c7b745459a9106e9eb13de10.jpg",
            wine_pairing: "Medium-bodied red wine like Merlot",
            dessert_pairing: "Gulab Jamun"
        },
        {
            id: 4,
            name: "Steak Frites",
            short_description: "A bistro classic: perfectly grilled steak meeting the crispiness of French fries.",
            long_description: "Every piece of steak tells a story of meticulous selection, aging, and grilling, creating a melt-in-mouth experience. Paired with golden, crispy fries, this dish embodies the spirit of Parisian bistros. The accompanying sauce, often a well-guarded secret, elevates the experience to a culinary ballet.",
            image_url: "https://images.food52.com/zA_65qNitNW9SWneIVcrqELWMJ8=/1200x1200/52096452-f9c1-49e9-9e76-46c694bee2ba--2020-1124_steak-frites_3x2_rocky-luten_032.jpg",
            wine_pairing: "A robust red wine like Bordeaux",
            dessert_pairing: "Crème brûlée"
        },
        {
            id: 5,
            name: "Tom Yum Goong",
            short_description: "Thailand in a bowl: a tantalizingly spicy and sour shrimp soup.",
            long_description: "Tom Yum Goong is more than a dish; it's an emotion. It carries the soul of Thai cuisine, blending the fierceness of chili with the tanginess of lemongrass and lime. The shrimp, tender and juicy, adds depth, making every spoonful a dive into the very heart of Thai traditions.",
            image_url: "https://hot-thai-kitchen.com/wp-content/uploads/2013/03/tom-yum-goong-blog.jpg",
            wine_pairing: "Light aromatic white wine like Gewürztraminer",
            dessert_pairing: "Mango Sticky Rice"
        }
    ]
};

export default mockData;

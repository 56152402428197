import React, { useState } from 'react';
import { supabase } from '../createClient'; // Replace with your actual Supabase initialization file
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Label, TextInput, Button } from 'flowbite-react';
import { Helmet } from 'react-helmet';
export default function LoginPage() {
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const navigate = useNavigate();




    const handleLogin = async () => {
        const { user, error } = await supabase.auth.signInWithPassword({
          email: loginEmail,
          password: loginPassword,
        });
    
        if (error) {
          window.sa_event("failed_login", {
            method: "Email",
            error: error.message,
          });
          console.error("Error logging in:", error.message);
          alert(error.error_description || error.message);
          // Optionally show a user-friendly error message
        } else {
          window.sa_event("successful_login", {
            method: "Email",
          });
          console.log("User logged in:", user);
          // Handle successful login redirect to /dashboard/menu
          // navigate("/dashboard/menu", { state: { image: selectedImage } });
          navigate("/dashboard");
        }
      };
    return (
        <div className="flex h-screen">
            <Helmet>
                <title>Login to MenuMystic</title>
                <meta name="description" content="Login to MenuMystic and understand any menu at any restaurant." />
                <link rel="canonical" href="https://www.menumystic.com/login" />
            </Helmet>
            {/* Left Side */}
            <div className="flex-1 h-3/4 text-black flex flex-col items-center justify-center bg-white p-8 ">
                <h1 className="text-3xl text-center mb-2 font-bold">Discover Cuisine Like Never Before!</h1>
                <p className="text-xl mb-8 text-center">
                Dive into a world of culinary insights. Navigate any menu with confidence and curiosity.
                </p>
                
                <Label htmlFor="loginEmail" className="mb-4 text-lg">Your Email</Label>
                <TextInput 
                    id="loginEmail"
                    className="mb-4 w-80"
                    value={loginEmail} 
                    onChange={(e) => setLoginEmail(e.target.value)} 
                />
                <Label htmlFor="loginPassword" className="mb-4 text-lg">Your Password</Label>
                <TextInput 
                    id="loginPassword" 
                    type="password" 
                    className="mb-4 w-80"
                    value={loginPassword} 
                    onChange={(e) => setLoginPassword(e.target.value)} 
                />
                <Button color="primary" onClick={handleLogin} className="mb-2  text-white p-2 rounded w-80">Start Journey</Button>
                <p className="text-gray-500">New here? <Link to="/register"> <span className="text-orange-700 hover:underline cursor-pointer">Sign up now</span></Link></p>
            </div>

            {/* Right Side (Hidden on mobile using `lg:flex` and `hidden`) */}
            <div className="lg:flex items-center justify-center hidden flex-1 bg-gray-300">
                {/* <img src="path_to_your_image.jpg" alt="Your Descriptive Alt Text" className="w-full h-full object-cover" /> */}
                <img src="/iphone14.png"
alt="iphone14 display menumystic app" className="h-4/5 object-cover"  />
            </div>
        </div>
    );
}



import React from "react"
import { useEffect, useState } from "react";
import { supabase } from "../createClient";
import { Link } from "react-router-dom";

export default function Footer() {
    const [blogPosts, setBlogPosts] = useState([]);
  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    console.log("Fetching blog posts...");
    let { data: posts, error } = await supabase.from("food").select("*");
    console.log(posts);

    if (error) console.log("Error: ", error);
    else setBlogPosts(posts);
  };
    return (
        console.log("Footer"),
        <div className="bg-gray-200 ">
        <div className="flex text-black flex-col md:flex-row   justify-between h-full py-6  px-6 sm:px-8 lg:px-20">
          <div className="flex  flex-col space-y-2">
            <div className="flex items-center">
              <div className=" m-[8px] text-5xl z">🍝</div>
              <div className=" text-[21px] font-bold">
                Menu<span className="text-orange-500">Mystic</span>
              </div>
            </div>
            <div>Lost in the menu? We're here to guide you ❤️</div>
            <div>Copyright © 2023 - All right reserved</div>
            <div>Made by Mateusz, here is my </div><a href="https://twitter.com/betterMateusz" >Twitter</a>
          </div>
          <div className="md:ml-10 flex flex-col space-y-2 items-baseline mt-4 md:mt-0">
            <div className="font-bold text-[24px]">Links</div>
            <a
              href="#"
              className="  hover:text-white text-gray-900  rounded-md text-sm font-medium"
            >
              How it works
            </a>
            <a
              href="#"
              className="  hover:text-white text-gray-900  rounded-md text-sm font-medium"
            >
              Blog
            </a>
            <a
              href="#"
              className="  hover:text-white text-gray-900  rounded-md text-sm font-medium"
            >
              FAQ
            </a>
          </div>
          <div className="md:ml-10 flex flex-col space-y-2 items-baseline mt-4 md:mt-0 ">
            <div className="font-bold text-[24px]">Legal</div>
            <Link
              to="/privacy-policy"
              className="  hover:text-white text-gray-900  rounded-md text-sm font-medium"
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms-and-conditions"
              className="  hover:text-white text-gray-900  rounded-md text-sm font-medium"
            >
              Terms and Conditions
            </Link>
          </div>
          <div className="md:ml-10 flex flex-col space-y-2 items-baseline mt-4 md:mt-0 ">
            <div className="font-bold text-[24px]">Food Blog</div>
            {blogPosts.map((post) => (
              <Link
                to={`/blog/${post.id}`}
                className="  hover:text-white text-gray-900  rounded-md text-sm font-medium"
              >
                {post.name}
              </Link>
            ))}
            {/* <a
              href="#"
              className=" hover:bg-gray-700 text-gray-900  rounded-md text-sm font-medium"
            >
              Privacy Policy
            </a>
            <a
              href="#"
              className=" hover:bg-gray-700 text-gray-900  rounded-md text-sm font-medium"
            >
              Terms of Service
            </a> */}
          </div>
        </div>
      </div>
    )
}
import React from "react";
import { HiCamera } from "react-icons/hi";
import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../createClient";
import axios from "axios";
import { useState, useEffect } from "react";
import { Modal, Flowbite, Spinner, Label, TextInput } from "flowbite-react";
import AdjustableImage from "../utils/AdjustableImage";
const getAuthToken = async () => {
  const { data, error } = await supabase.auth.getSession();
  if (error) {
    console.error("Error getting user session:", error);
    throw error;
  }
  // console.log("Session:", data["session"]["access_token"]);
  return data ? data.session.access_token : null;
};

export default function Dashboard() {
  const client = axios.create({
    // baseURL: "http://127.0.0.1:8080",
    baseURL: "https://striped-guard-429700-f5.uc.r.appspot.com/",
  });
  const customTheme = {
    theme: {
      modal: {
        root: {
          show: {
            on: "flex  bg-white bg-opacity-50 backdrop-blur-md",
          },
        },
      },
    },
  };
  const navigate = useNavigate();
  const [restaurants, setRestaurants] = useState([]);
  const [modal, setModal] = useState("loading");
  const [selectedRestaurant, setSelectedRestaurant] = useState([]);
  const [newName, setNewName] = useState("");
  const [selectedImageSrc, setSelectedImageSrc] = useState(null);
  const [selectedCorners, setSelectedCorners] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [creditsLeft, setCreditsLeft] = useState(0);

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  };
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      setSelectedImage(file);
      setSelectedImageSrc(URL.createObjectURL(file)); // Convert File to URL
      setModal("crop_image");
    }
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error signing out:", error.message);
      alert("Error signing out");
      return;
    }
    navigate("/");
  };

  const handleImageCrop = async () => {
    if (selectedCorners.length !== 4) {
      alert("Please select 4 corners");
      return;
    }
    const { data, error } = await supabase.auth.getSession();
    if (!data || !data["session"]) {
      console.log("not logged in");
      // setModal("create_account");
      navigate("/login");
    } else {
      console.log("logged in");
      navigate("/dashboard/menu", {
        state: { image: selectedImage, coordinates: selectedCorners },
      });
    }
    if (error) {
      console.error("Error getting user session:", error);
      alert("Error getting user session");
      throw error;
    }
  };

  const loadRestaurants = async () => {
    const token = await getAuthToken();
    if (!token) {
      alert("No token found. Please log in again.");
      return;
    }
    // data is empty, pass only header with token
    try {
      const response = await client.post("get-restaurants", null, {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Response:", response);
      if (response.data.message == "No account") {
        setRestaurants([]);
      } else {
        setRestaurants(response.data);
      }
      setModal(null);
    } catch (error) {
      console.log(error);
      alert("Error getting restaurants");
      setRestaurants([]);
      setModal(null);
    }
  };

  const handleRestaurantEdit = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setModal("edit");
  };

  const handleRemoveRestaurant = async () => {
    const token = await getAuthToken();
    if (!token) {
      alert("No token found. Please log in again.");
      return;
    }
    const formData = new FormData();
    formData.append("restaurant_id", selectedRestaurant.id);
    const response = await client.post("delete-restaurant", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Response:", response);
    
    loadRestaurants();
  };

  const handleChangeName = async () => {
    console.log("in handle change name");
    const token = await getAuthToken();
    if (!token) {
      alert("No token found. Please log in again.");
      return;
    }
    const formData = new FormData();
    console.log("selected restaurant:", selectedRestaurant);
    formData.append("restaurant_id", selectedRestaurant.id);
    console.log("new name:", newName);
    formData.append("restaurant_name", newName);
    const response = await client.post("edit-restaurant", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Response:", response);
    loadRestaurants();
  };

  const getCreditsLeft = async () => {
    const token = await getAuthToken();
    if (!token) {
      alert("No token found. Please log in again.");
      return;
    }
    try {
      const response = await client.post("get-credits", null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Response:", response);
      setCreditsLeft(response.data.credits);
    } catch (error) {
      console.error("Error getting credits:", error);
      setCreditsLeft(3);
    }
  };

  useEffect(() => {
    getCreditsLeft();
    loadRestaurants();
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <h1 className="flex flex-col justify-center py-[32px] px-[16px] items-center ">
        <span className="mb-2 text-2xl md:text-5xl font-bold text-gray-900 ">
          Your Restaurants
        </span>
        <span className="bg-orange-500 text-white rounded-full px-4 py-1 text-sm md:text-base">
          {creditsLeft === 0
            ? "No Scans Left, Upgrade to Premium"
            : creditsLeft === -5
            ? "Unlimited Scans Left"
            : `${creditsLeft} Scans Left`}
        </span>
      </h1>

      <div className="px-[16px] grid gap-[16px] xl:grid-cols-3 sm:grid-cols-2 grid-cols-1">
        {restaurants.map((restaurant) => (
          <div key={restaurant.id} className="flex meal-container">
            <div className="flex bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 w-full">
              <div className="flex flex-col justify-between p-4 leading-normal flex-grow">
                <h5 className="mb-2 text-xl md:text-2xl font-bold tracking-tight text-gray-900 ">
                  {restaurant.restaurant_name}
                </h5>
                <p className="mb-3 text-xs md:text-sm font-normal text-gray-700 ">
                  Created on {formatDate(restaurant.created_at)}
                </p>

                <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2">
                  <Button
                    color="primary"
                    className="w-full md:w-1/2 h-full bg-orange-500 hover:bg-orange-600"
                    onClick={() => {
                      handleRestaurantEdit(restaurant);
                    }}
                  >
                    <div className="text-xs md:text-md">Edit details</div>
                  </Button>
                  <Button
                    color="primary"
                    className="w-full md:w-1/2 h-full bg-orange-500 hover:bg-orange-600"
                    onClick={() => {
                      navigate("/dashboard/menu", {
                        state: { restaurant_id: restaurant.id },
                      });
                    }}
                  >
                    <div className="text-xs md:text-md">View Menu</div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center my-8">
        {" "}
        {/* This div is used to center the button */}
        <Button color="primary">
          <label className="flex items-center justify-center text-md md:text-lg">
            <input
              type="file"
              accept="image/*"
              alt="Take a picture"
              className="opacity-0 absolute"
              onChange={handleImageChange}
            />
            <p>Add a new restaurant</p>
            <HiCamera className="ml-2" />
          </label>
        </Button>
      </div>
      <div className="flex justify-center my-8">
        {" "}
        {/* This div is used to center the button */}
        <Button onClick={signOut} color="primary">
          <label className="flex items-center justify-center text-md md:text-lg">
            <p>Sign out</p>
          </label>
        </Button>
      </div>
      {/* </div> */}

      {/* </div> */}

      <Flowbite theme={customTheme}>
        <Modal show={modal === "loading"} size="sm">
          {/* <Modal.Header /> */}
          <Modal.Body>
            <div className="flex text-black h-full flex-col items-center space-y-2 justify-center">
              <h5 className="font-bold text-3xl">Working on it...</h5>
              <Spinner color="warning" size="xl" />
              <p className="text-gray-900 text-center">
                Hang tight! We're loading your restaurants.
              </p>
            </div>
          </Modal.Body>
        </Modal>
      </Flowbite>
      <Modal
        show={modal === "edit"}
        size="md"
        popup
        onClose={() => {
          setModal(undefined);
          setNewName("");
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-white space-y-6">
            <h3 className="text-xl font-bold text-gray-900">
              Edit restaurant details
            </h3>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="name" value="New name" />
              </div>

              <TextInput
                id="name"
                placeholder={selectedRestaurant.restaurant_name}
                value={newName} // assuming you have a separate state for this
                onChange={(e) => setNewName(e.target.value)}
                required
              />
            </div>

            {/* Put two buttons next to each other */}
            <div className="flex space-x-2">
              <Button
                color="primary "
                className="bg-orange-500 hover:bg-orange-600"
                onClick={() => {
                  // handleEdit();
                  handleChangeName();
                  setModal(undefined);
                  setNewName("");
                }}
              >
                Save changes
              </Button>
              <Button
                color="danger"
                className="bg-red-500 hover:bg-red-600"
                onClick={() => {
                  // handleEdit();
                  handleRemoveRestaurant();
                  setModal(undefined);
                  setNewName("");
                }}
              >
                Remove restaurant
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {modal == "crop_image" ? (
        <div className="fixed top-0 left-0 z-200 h-screen w-screen h-screen bg-black flex flex-col  pt-4 pb-28 justify-between items-center">
          <AdjustableImage
            src={selectedImageSrc}
            onCornersChange={(corners) => setSelectedCorners(corners)}
          />
          <div>
            <Button
              onClick={handleImageCrop}
              size="xl"
              className="mt-4"
              color="primary"
            >
              Accept
            </Button>
            <Button
              onClick={() => setModal(undefined)}
              size="xl"
              className="mt-4"
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
